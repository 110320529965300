

import { computed, ComputedRef, defineComponent, toRefs } from "vue";
import TheList from "@/components/List/TheList.vue";
import { ItemsList } from "@/components/List/ItemList";
import TheCard from "@/components/Card/TheCard.vue";
import { Speaker } from "@/interfaces/Program/Speaker";
import usePushLink from "@/composables/usePushLink";
import { useRoute } from "vue-router";

export default defineComponent({
  name: 'TheExibitors',
  components: {
    TheCard,
    TheList
  },
  props: {
    exhibitors: Array as () => Speaker[]
  },
  setup(props) {
    const { exhibitors } = toRefs(props)
    const { getUrl } = usePushLink()

    const route = useRoute()
    const eventId: string = route.params.eventId.toString()

    const exhibitorsList: ComputedRef<ItemsList[]> = computed(() => {
      if ( exhibitors.value ) {
        return exhibitors.value.map((exhibitor) => {
          return {
            subtitle: exhibitor.cargo,
            title: exhibitor.nombre,
            thumb: (exhibitor.avatar)? exhibitor.avatar : '/img/avatar.svg',
            link: getUrl('Speaker',{ eventId: eventId, speakerId: exhibitor.id.toString()})
          }
        })
      }
      return []
    })

    return {
      exhibitorsList
    }

  }
})
