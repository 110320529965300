<template>
  <ion-page>
    <TheHeaderLarge :subseccion-no-trans="title"/>
    <ion-content fullscreen v-if="presentation">
      <AppInfoGtm/>
      <TheCard :category="category" :title="presentation.titulo" :description="presentation.descripcion"/>
      <TheExibitors :exhibitors="presentation.expositores"/>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <template v-if="agendado">
          <ion-fab-button color="danger" @click="removePresentation(presentation.id)">
            <ion-icon :icon="trash"></ion-icon>
          </ion-fab-button>
        </template>
        <template v-else>
          <ion-fab-button @click="add(presentation)">
            <ion-icon :icon="today"></ion-icon>
          </ion-fab-button>
        </template>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent, IonFab, IonFabButton, IonIcon,
  IonPage,
} from '@ionic/vue';
import { trash, today } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import TheHeaderLarge from "@/components/TheHeaderLarge.vue";
import { useRoute } from "vue-router";
import TheCard from "@/components/Card/TheCard.vue";
import TheExibitors from "@/components/Program/TheExhibitors.vue";
import { Presentation } from "@/interfaces/Program/Presentation";
import { programService } from "@/services/program.service";
import useMyAgenda from "@/composables/useMyAgenda";
import UsePushLink from "@/composables/usePushLink";
import AppInfoGtm from "@/components/GTM/AppInfoGtm.vue";
import useDateManager from "@/composables/useDateManager";

export default defineComponent({
  name: 'Presentation',
  components: {
    AppInfoGtm,
    TheExibitors,
    TheCard,
    TheHeaderLarge,
    IonContent,
    IonPage,
    IonFab,
    IonFabButton,
    IonIcon,
  },
  setup() {
    const route = useRoute()
    const eventId: string = route.params.eventId.toString()
    const presentationId: string = route.params.presentationId.toString()
    const title = ref('')
    const presentation = ref<Presentation>()
    const { addPresentation, removePresentation, hasPresentation } = useMyAgenda(eventId)
    const { getUrl } = UsePushLink()
    const category = ref('')
    const { formatHourHtml } = useDateManager()

    const add = (presentation) => {

      if ( !hasPresentation(presentation.id) ) {

        const ItemsListPresentation = {
          id: presentation.id,
          title: presentation.titulo,
          hour: presentation.hora,
          category: presentation.keynote ? 'Keynote' : undefined,
          important: (presentation.keynote),
          link: getUrl('Presentation', { eventId: eventId, presentationId: presentation.id.toString() }),
          extraData: {
            day: presentation.dia + ' ' + presentation.fecha,
            selected: hasPresentation(presentation.id),
          }
        }
        addPresentation(ItemsListPresentation)
      }
    }

    const agendado = computed(() => {

      if ( !presentation.value ) {
        return false
      }

      if ( hasPresentation(presentation.value.id) ) {
        return true
      }

      return false
    })

    onMounted(async () => {
      const presentationData = await programService.presentation(eventId, presentationId)
      presentation.value = presentationData
      category.value = formatHourHtml(presentationData.hora)
      title.value = presentationData.dia + ' - ' + presentationData.fecha
    })

    return {
      title,
      presentation,
      today,
      trash,
      add,
      removePresentation,
      agendado,
      category
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>